<template>
    <div class="banner banner--user-is-freezed" :class="{'i18n--loading': i18nLoading }">
        <div class="page__wrapper">
            <div class="banner__content d-block">
                <p>
                    {{ $t('paragraphs[0]') }}
                </p>
                <p class="text--bold">
                    {{ $t('paragraphs[1]') }}
                    <a class="link link--blue text--underlined" href="https://tagme.sberdevices.ru/" target="_blank" rel="nofollow">
                        {{ $t('tagme') }}
                    </a>
                </p>
                <p class="mb-0">
                    {{ $t('paragraphs[2]') }}
                    <a class="link link--blue" href="https://tagme.sberdevices.ru/docs/marker/faq.html" target="_blank" rel="nofollow">
                        {{ $t('link') }}
                    </a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

    import mixinI18n from '@/mixins/mixinI18n.js';
    import { defaultLang } from '@/i18n/components/banners/ElBannerUserIsFreezed';

    export default {
        name: 'ElBannerUserIsFreezed',
        mixins: [
            mixinI18n
        ],
        components: {

        },
        data() {
            return {

            }
        },
        i18n: {
            messages: defaultLang
        },
        methods: {
            async loadI18nFile(lang){
                let loadModule = () => import(`@/i18n/components/banners/ElBannerUserIsFreezed/${lang}.js`);
                await loadModule().then(module => {
                    this.$i18n.setLocaleMessage(lang, module.default);
                });
            }
        },
        mounted() {

        }
    }
</script>

import { request } from '@/lib/ajax.js';
import store from '@/store/store';
import router from '@/router'
import localStorage from 'store';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { events } from '@/lib/events';

export const libAuth = (function () {

    return {
        loginConfirm: (loginData) => {
            return new Promise((resolve, reject) => {
                libAuth.getFingerprint().then(fingerprint => {
                    request({
                        url: 'api/v1/auth/login',
                        noauth: true,
                        data: {
                            ...loginData,
                            fingerprint: fingerprint
                        }
                    }).then( resp => {
                        resp.data.user.logo = `${store.getters.apiSettings.base}logo/${resp.data.user.logo_dir}/logo.jpg`;
                        store.dispatch('loadUserInfo', {
                            user: resp.data.user,
                            balance: resp.data.balance
                        });
                        libAuth.setAuthData(resp.data.tokens);
                        resolve(resp);
                    }).catch(resp => {
                        reject(resp);
                    });
                });
            });
        },
        expelUser(){
            store.dispatch('clearUserInfo');
            let routeMatch = router.matcher.match(document.location.pathname);
            if (routeMatch.name && routeMatch.name !== 'index') {
                router.push({ name: 'index' });
            }
        },
        logout: () => {
            return new Promise((resolve, reject) => {
                request({
                    url: 'api/v1/auth/logout'
                }).then(success => {
                    libAuth.expelUser();
                    resolve(true);
                });
            });
        },
        getFingerprint() {
            return new Promise((resolve, reject) => {
                if( store.getters.fingerprint ){
                    resolve(store.getters.fingerprint)
                }else{
                    const fpPromise = FingerprintJS.load({
                        monitoring: false
                    });
                    (async () => {
                        const fp = await fpPromise,
                            result = await fp.get(),
                            { screenFrame, screenResolution, fontPreferences, ...components } = result.components;//ignore some entropy modules issue #1655
                        if( components?.canvas?.value?.text ){
                            components.canvas.value.text = '';
                        }
                        const visitorId = FingerprintJS.hashComponents(components);
                        store.commit('setFingerprint', visitorId);
                        resolve(visitorId);
                    })();
                }
            })
        },
        isAccessTokenExpired() {
            const accessExpiresTime = store.getters.accessTokenExpires - 10,// кол-во секунд с Jan 1, 1970, 00:00:00.000 GMT - 10
                secondsNowUTC = (new Date().getTime() / 1000) >> 0;// new Date().getTime() - кол-во мс с Jan 1, 1970, 00:00:00.000 GMT
            return accessExpiresTime <= secondsNowUTC;
        },
        getRefreshToken(){
            return localStorage.get('refreshToken');
        },
        setAuthData(data){
            store.commit('setAccessToken', data.access);
            store.commit('setAccessTokenExpires', data.access_expires);
            localStorage.set('refreshToken', data.refresh);
        },
        refreshTokens(){
            store.commit('setUpdatingTokens', true);
            return new Promise((resolve, reject) => {
                let refresh = libAuth.getRefreshToken();
                libAuth.getFingerprint().then(fingerprint => {
                    request({
                        url: 'api/v1/auth/token/refresh',
                        noauth: true,
                        notifyOnError: false,
                        data: {
                            fingerprint: fingerprint,
                            refresh: refresh
                        }
                    }).then(resp => {
                        libAuth.setAuthData(resp.data.tokens);
                        resolve(true);
                    }).catch(error => {// refresh token invalid/expired
                        if (refresh !== libAuth.getRefreshToken()) {//updated by another request(from other browser tab)
                            resolve();
                        } else {
                            libAuth.expelUser();
                            window.dispatchEvent(new CustomEvent(events.EV_APP_SHOW_TOAST, {
                                detail: {
                                    type: 'error',
                                    msg: error.message
                                }
                            }));
                            reject(error);
                        }
                    }).finally( ()=> {
                        store.commit('setUpdatingTokens', false);
                    });
                });
            });
        }
    }

})();

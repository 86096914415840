<template>
    <icon-template
        :width="24"
        :height="24"
        name="Phone"
        >
        <path fill="currentColor" d="m20.45 19.348-1.28 1.28c-.228.227-.898.371-.918.371a15.126 15.126 0 0 1-10.824-4.425A15.126 15.126 0 0 1 3 5.714c0-.003.147-.653.375-.879l1.28-1.283c.47-.47 1.37-.68 2-.471l.27.09c.63.21 1.289.906 1.464 1.546l.644 2.367c.176.641-.06 1.555-.53 2.023l-.855.856a9.082 9.082 0 0 0 6.39 6.392l.855-.855c.47-.47 1.384-.706 2.026-.53l2.366.646c.64.173 1.335.831 1.545 1.462l.091.27c.21.63-.003 1.53-.472 2Z"/>
    </icon-template>
</template>

<script>
    import iconTemplate from '@/components/icons/iconTemplate';
    export default {
        components: {
            iconTemplate
        }
    }
</script>

<template>
    <icon-template
        :width="24"
        :height="24"
        name="MenuOpen"
        >
        <path fill="currentColor" d="M5 6h14v2H5V6ZM5 16h14v2H5v-2ZM5 11h14v2H5v-2Z"/>
    </icon-template>
</template>

<script>
    import iconTemplate from '@/components/icons/iconTemplate';
    export default {
        components: {
            iconTemplate
        }
    }
</script>

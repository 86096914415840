<template>
    <icon-template
        :width="25"
        :height="24"
        name="Sber"
        >
        <defs>
            <linearGradient id="piT9bkrQeb" x1="5.042" x2="2.015" y1="20.136" y2="11.381" gradientUnits="userSpaceOnUse">
                <stop offset=".14" stop-color="#F1E813"/>
                <stop offset=".3" stop-color="#E6E418"/>
                <stop offset=".58" stop-color="#C9DA26"/>
                <stop offset=".89" stop-color="#A2CC39"/>
            </linearGradient>
            <linearGradient id="tyAJswOyfN" x1="5.272" x2="13.073" y1="4.004" y2="1.031" gradientUnits="userSpaceOnUse">
                <stop offset=".06" stop-color="#0FA7DF"/>
                <stop offset=".54" stop-color="#0098F8"/>
                <stop offset=".92" stop-color="#0290EA"/>
            </linearGradient>
            <linearGradient id="e1vXLEIO9k" x1="12.184" x2="21.007" y1="22.324" y2="20.331" gradientUnits="userSpaceOnUse">
                <stop offset=".12" stop-color="#A2CC39"/>
                <stop offset=".28" stop-color="#86C239"/>
                <stop offset=".87" stop-color="#219F38"/>
            </linearGradient>
            <linearGradient id="abyl8v6lX0" x1="12.387" x2="19.93" y1=".928" y2="3.256" gradientUnits="userSpaceOnUse">
                <stop offset=".06" stop-color="#0290EA"/>
                <stop offset=".79" stop-color="#0C89CA"/>
            </linearGradient>
            <linearGradient id="uZ57g8SWxs" x1="4.764" x2="12.973" y1="19.71" y2="22.779" gradientUnits="userSpaceOnUse">
                <stop offset=".13" stop-color="#F1E813"/>
                <stop offset=".3" stop-color="#EAE616"/>
                <stop offset=".53" stop-color="#D8DF1F"/>
                <stop offset=".8" stop-color="#BAD52D"/>
                <stop offset=".98" stop-color="#A2CC39"/>
            </linearGradient>
            <linearGradient id="qxCQyvUDeX" x1="2" x2="5.294" y1="12.333" y2="3.762" gradientUnits="userSpaceOnUse">
                <stop offset=".07" stop-color="#A2CC39"/>
                <stop offset=".26" stop-color="#81C45E"/>
                <stop offset=".92" stop-color="#0FA7DF"/>
            </linearGradient>
        </defs>
        <path fill="#21A038" d="M22.463 4.662a12.03 12.03 0 0 1 1.438 2.386L12.97 15.105 8.4 12.24V8.797l4.569 2.863 9.493-6.998Z"/>
        <path fill="url(#piT9bkrQeb)" d="M3.74 11.999c0-.155.003-.308.01-.461l-2.768-.136a11.964 11.964 0 0 0 3.5 9.08l1.964-1.963a9.196 9.196 0 0 1-2.707-6.52Z"/>
        <path fill="url(#tyAJswOyfN)" d="M12.966 2.771c.155 0 .308.006.461.013l.139-2.769a11.97 11.97 0 0 0-9.085 3.5l1.963 1.963a9.195 9.195 0 0 1 6.522-2.707Z"/>
        <path fill="url(#e1vXLEIO9k)" d="M12.967 21.226c-.154 0-.308 0-.461-.013l-.139 2.768a11.959 11.959 0 0 0 9.081-3.5l-1.96-1.962a9.2 9.2 0 0 1-6.52 2.707Z"/>
        <path fill="url(#abyl8v6lX0)" d="m18.17 4.381 2.333-1.72A11.948 11.948 0 0 0 12.963 0v2.771a9.183 9.183 0 0 1 5.207 1.61Z"/>
        <path fill="#21A038" d="M24.967 11.998c.001-.721-.062-1.44-.189-2.15l-2.583 1.903v.247a9.202 9.202 0 0 1-3.04 6.838l1.863 2.06a11.967 11.967 0 0 0 3.949-8.898Z"/>
        <path fill="url(#uZ57g8SWxs)" d="M12.967 21.225a9.208 9.208 0 0 1-6.84-3.04l-2.059 1.862a11.967 11.967 0 0 0 8.899 3.95v-2.772Z"/>
        <path fill="url(#qxCQyvUDeX)" d="M6.78 5.16 4.916 3.1a11.967 11.967 0 0 0-3.95 8.898h2.772a9.203 9.203 0 0 1 3.04-6.839Z"/>
    </icon-template>
</template>

<script>
    import iconTemplate from '@/components/icons/iconTemplate';
    export default {
        components: {
            iconTemplate
        }
    }
</script>

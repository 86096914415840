<template>
    <div>
        <div class="text text--accented mb-3" v-if="title">
            {{ title }}
        </div>
        <p v-if="message">
            {{ message }}
        </p>
        <a class="btn btn--sm btn--outline-primary" :href="href" v-if="href">
            {{ btnText }}
        </a>
    </div>
</template>

<script>

    export default {
        name: 'ElToastMessage',
        props: {
            title: {
                type: String,
                default: ''
            },
            btnText: {
                type: String,
                default: 'Read'
            },
            message: {
                type: String,
                default: ''
            },
            href: {
                type: String,
                default: ''
            }
        },
        data(){
            return{

            }
        }
    }
</script>

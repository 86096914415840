import { events } from '@/lib/events';

export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            i18nLoading: false
        }
    },
    computed: {

    },
    methods: {
        loadI18nFile(lang){
            //will be redeclared inside component
        },
        addI18nFile(lang){
            this.i18nLoading = true;
            this.loadI18nFile(lang).finally(() => {
                this.i18nLoading = false;
            });
        },
        checkI18nLocale(){
            let lang = this.$store.getters.getLang;// locale from localStorage (or default)
            if ( !this.$i18n?.availableLocales.includes(lang) ) {
                this.addI18nFile(lang);
            }
        },
        i18nToggleLangHandler(lang){
            if( !this.$i18n?.availableLocales.includes(lang) ) {
                this.addI18nFile(lang);
            }
        }
    },
    created() {
        this.checkI18nLocale();
    },
    mounted() {
        this.$evbus.$on(events.EV_APP_TOGGLE_LANG, this.i18nToggleLangHandler);
    },
    beforeDestroy(){
        this.$evbus.$off(events.EV_APP_TOGGLE_LANG, this.i18nToggleLangHandler);
    }
}

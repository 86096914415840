import { render, staticRenderFns } from "./iconCloseThin.vue?vue&type=template&id=972e476e"
import script from "./iconCloseThin.vue?vue&type=script&lang=js"
export * from "./iconCloseThin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
    <ul class="menu">
        <el-preloader
            v-if="!menu.length"
            :text="''"
            class="preloader--sm m-0 ml-2"
            >
        </el-preloader>
        <li
            class="menu__item"
            v-for="(item, index) in menu"
            :key="`user_menu_${index}`"
            :class="{
                'menu__item--active': $route.name === item.link,
                'menu__item--ancestor': isCurrentRouteAncestor(item)
            }"
            >
            <div class="nav__item" @click="closeMenu($event)">
                <router-link
                    v-if="item.link"
                    class="link"
                    :to="{name: item.link}"
                    >
                    {{ getMenuItemTitle(item) }}
                </router-link>
                <span
                    v-else
                    class="link"
                    >
                    {{ getMenuItemTitle(item) }}
                </span>
                <div
                    class="badge"
                    v-if="item.link === 'messenger' && cnt > 0"
                    >
                    {{ notificationsCount }}
                </div>
            </div>
            <ul class="menu" v-if="item.inner && item.inner.length">
                <li
                    class="menu__item"
                    v-for="(item_inner, index_inner) in item.inner"
                    :key="`user_menu_${index}_${index_inner}`"
                    :class="{'menu__item--active': $route.name === item_inner.link}"
                    >
                    <div class="nav__item" @click="closeMenu($event)">
                        <router-link
                            v-if="item_inner.link"
                            class="link"
                            :to="{name: item_inner.link}"
                            >
                            {{ getMenuItemTitle(item_inner) }}
                        </router-link>
                        <span
                            v-else
                            class="link"
                            >
                            {{ getMenuItemTitle(item_inner) }}
                        </span>
                        <div
                            class="badge"
                            v-if="item_inner.link === 'messenger' && cnt > 0"
                            >
                            {{ notificationsCount }}
                        </div>
                    </div>
                </li>
            </ul>
        </li>
        <slot name="menu-append"></slot>
    </ul>
</template>

<script>

    import ElPreloader from '@/components/ElPreloader';

    export default {
        name: 'ElHeaderMenu',
        props: {
            menuItems: {
                type: Array,
                default: () => ([])
            },
            cnt: {
                type: Number,
                default: 0
            }
        },
        components: {
            ElPreloader
        },
        data() {
            return {
                menu: this.menuItems
            }
        },
        watch: {
            menuItems(newVal, oldVal){
                this.menu = newVal;
            }
        },
        computed: {
            notificationsCount(){
                return this.cnt > 99 ? '99+' : this.cnt;
            }
        },
        methods: {
            getMenuItemTitle(menuItem){
                let resp = menuItem.title,
                    routeExists = !!this.$router.options.routes.find( route => route.name === menuItem.link);
                if( routeExists ){
                    resp = this.$t(`viewTitles.${menuItem.link.replaceAll('-', '_')}`)
                }
                return resp;
            },
            isCurrentRouteAncestor(menuItem) {
                let resp = false;
                if( menuItem.inner && menuItem.inner.length ){
                    resp = !!menuItem.inner.find( item => item.link === this.$route.name );
                }
                return resp;
            },
            closeMenu(evt) {
                if( evt.target.tagName.toLowerCase() !== 'a' ){ return; }// only links
                this.$emit('close-menu');
            }
        }
    }
</script>

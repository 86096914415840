<template>
    <icon-template
        :width="24"
        :height="24"
        name="PreloaderCircle"
        >
        <path fill="transparent" stroke="currentColor" stroke-opacity=".502" stroke-width="2" d="M21 12a9 9 0 1 1-18.002-.002A9 9 0 0 1 21 12Z"/>
        <path fill="transparent" stroke="currentColor" stroke-width="2" d="M21 12c0-4.952-4.048-9-9-9">
            <animateTransform fill="remove" accumulate="none" additive="replace" attributeName="transform" calcMode="linear" dur="1s" from="0 12 12" repeatCount="indefinite" restart="always" to="360 12 12" type="rotate"/>
        </path>
    </icon-template>
</template>

<script>
    import iconTemplate from '@/components/icons/iconTemplate';
    export default {
        components: {
            iconTemplate
        }
    }
</script>

<template>
    <icon-template
        :width="24"
        :height="24"
        name="MenuClose"
        >
        <path fill="currentColor" d="M7 16.258 16.258 7l1.156 1.157-9.258 9.257L7 16.258Z"/>
        <path fill="currentColor" d="m8.157 7 9.257 9.254-1.156 1.16L7 8.157 8.157 7Z"/>
    </icon-template>
</template>

<script>
    import iconTemplate from '@/components/icons/iconTemplate';
    export default {
        components: {
            iconTemplate
        }
    }
</script>

<template>
    <footer class="page__footer footer" :class="{'footer--front-page': $route.name === 'index'}">
        <div class="page__wrapper">
            <div class="footer__wrapper">
                <div class="footer__info">
                    <div class="info info--phone" data-v-step="hotline-phone">
                        <div class="info__value">
                            <icon-phone></icon-phone>
                            <a :href="`tel:${$store.getters.hotLinePhone2Call}`">
                                {{ $store.state.project.hotLinePhone }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="footer__scroll-top">
                    <button
                        v-if="showScrollBtn"
                        class="btn btn--action"
                        :title="$t('btns.scroll_top')"
                        @click="scrollToTop()"
                        >
                        <icon-scroll-top></icon-scroll-top>
                    </button>
                </div>
            </div>
            <div class="footer__copy">
                <span>
                    &copy;&nbsp;{{ $store.state.project.title }}, {{ year }}
                </span>
                <span class="ml-2" v-if="release">
                    {{ release }}
                </span>
            </div>
        </div>
    </footer>
</template>

<script>

    import iconPhone from '@/components/icons/iconPhone';
    import iconScrollTop from '@/components/icons/iconScrollTop';

    export default {
        name: 'ElFooter',
        components: {
            iconPhone,
            iconScrollTop
        },
        data() {
            return {
                year: new Date().getFullYear(),
                showScrollBtn: false,
                resizeObserver: null,
                resizeObserverAnimFrame: null,
                release: process.env.VUE_APP_RELEASE || ''
            }
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            toggleScrollBtn(body){
                this.showScrollBtn = body.scrollHeight > window.outerHeight*2 ? true : false;
            }
        },
        mounted(){
            if( typeof ResizeObserver !== 'undefined' ){
                this.resizeObserver = new ResizeObserver(entries => {
                    this.resizeObserverAnimFrame = requestAnimationFrame(() => {
                        this.toggleScrollBtn(entries[0].target);
                    });
                });
                this.resizeObserver?.observe(document.body);
            }
        },
        beforeDestroy(){
            if( this.resizeObserverAnimFrame ){
                cancelAnimationFrame(this.resizeObserverAnimFrame);
            }
            if( this.resizeObserver ){
                this.resizeObserver.disconnect();
            }
        }
    }
</script>

import { required, integer, decimal, minValue, maxValue, helpers } from 'vuelidate/lib/validators';

function isPhone(value){
    value = value || '';
    let match_rez = value.match(/^\+?[\d| | (|)|-]*/);
    return match_rez[0].length === value.length;
}

function isPhoneWith7(value){
    value = value || '';
    let match_rez = value.match(/^7\d{10}/);
    return match_rez && (match_rez[0].length === value.length) ? true : false;
}

function isValidFio(value){
    value = value || '';
    let rusLetters = value.match(/[а-яёА-ЯЁ]/gm),
        enLetters = value.match(/[a-zA-Z]/gm),
        allowedLetters = value.match(/[а-яёА-ЯЁa-zA-Z- ()`'\d\.]/gm),
        allLettersAreAllowed = allowedLetters ? allowedLetters.length === value.length : true,
        allEnLettersAreAllowed = enLetters ? enLetters.every( item => ['I', 'V', 'X'].includes(item)) : true,
        alphabetsAreMixed = rusLetters && enLetters && !allEnLettersAreAllowed,
        resp = !alphabetsAreMixed && allLettersAreAllowed && value.length > 0;
    return !helpers.req(value) || resp;
}

function isGreaterThanZero(value){
    return value > 0;
}

function isValidExecutionTime(value){
    return value >= 30 && value <= 359999;
}

function isValidPriority(value){
    return value !== null ? value >= 0 && value <= 100 : true;
}

function isValidPrice(value){
    let resp = true;
    if( value ){
        resp = value.trim().length ? !isNaN(parseFloat(value)) : true;
    }
    return resp;
}

function isValidConsensus(value){
    return value !== null ? value >= 0.1 && value <= 0.9 : true;
}

function isValidDate({date_from, date_to}){
    let resp = true;
    if( date_from !== undefined && date_to !== undefined){
        let from = Date.parse(date_from),
            to = Date.parse(date_to);
        if( to < from ){
            resp = false;
        }
    }
    return resp;
}

function isResidentPaymentAccount(value){
    let resp = true;
    if( value && value.length && value.toString().indexOf(40820) === 0 ){//если начинается с 40820 - признак нерезидента
        resp = false;
    }
    return resp;
}

function getVuelidateScheme(validationSpec = {}){
    let resp = {};
    if( validationSpec.required ){
        resp['required'] = required;
    }
    if( validationSpec.integer ){
        resp['integer'] = integer;
    }
    if( validationSpec.float ){
        resp['decimal'] = decimal;
    }
    if( validationSpec.minValue !== undefined ){
        resp['minValue'] = minValue(validationSpec.minValue);
    }
    if( validationSpec.maxValue !== undefined ){
        resp['maxValue'] = maxValue(validationSpec.maxValue);
    }
    return resp;
}

function isValidHotkeys(currentRow, rowsArray){
    let resp = true;
    if( currentRow?.hotkeys?.length ){
        let allCombos = [];
        rowsArray.map( row => {
            if( row?.hotkeys?.length && row.id !== currentRow.id ){
                row.hotkeys.map( item => {
                    allCombos = [...allCombos, ...item.combo];
                });
            }
        });
        currentRow.hotkeys.map( item => {
            item.combo.map( combo => {
                if( allCombos.includes(combo) ){
                    resp = false;
                }else{
                    allCombos.push(combo);
                }
            });
        });
    }
    return resp;
}

function isUniqueResultTitle(currentRow, rowsArray) {
    let resp = true;
    if( rowsArray?.length ){
        let allResultTitles = [];
        rowsArray.map( row => {
            if( row.id !== currentRow.id ){
                allResultTitles.push( row.result_title );
            }
        });
        if( allResultTitles.includes(currentRow.result_title) ){
            resp = false;
        }
    }
    return resp;
}

function isNotReservedResultTitle(item){
    let reservedResultTitles = ['HONEYPOT_IGNORE_THIS_FIELD', 'EDUCATION_IGNORE_THIS_FIELD'];
    return !reservedResultTitles.includes(item.result_title);
}

function isValidModerationTotalAnswers(total_answers, dataForm){
    return (dataForm.moderation_type === '1') && (total_answers < 2) ? false : true;// '1' - enabled, '2' - disabled
}

function isValidIou(value){
    return value !== undefined ? (value >= 0 && value <= 1) : true;
}

function isValidDiffAnnotator(value){
    return value !== undefined ? value >= 0 : true;
}

function isValidSkills(projectJson, skillsFilterId) {
    let includeFilterSkillIds = [];
    if( projectJson.filter?.include?.[skillsFilterId] ){
        if( Array.isArray(projectJson.filter.include[skillsFilterId].data) ){
            projectJson.filter.include[skillsFilterId].data.map( skill => {
                if( skill.id !== null ){
                    includeFilterSkillIds.push( parseInt(skill.id) );
                }
            });
        }
    }
    if( includeFilterSkillIds.length ){
        return includeFilterSkillIds.every( skillId => projectJson.skills.includes(skillId) || projectJson.skills.includes(skillId.toString()) );
    }
    return true;
}

function isValidExportFormat(value, availibleFormats){
    if( value === null ){ return true; }
    return availibleFormats.includes(value) ? true : false;
}

function isValidRuleCommon(condition, elements = []){

    function getHierselectRts(values = []) {
        let resp = [];
        values.map( item => {
            if( item.inner === null ){
                resp.push( item.result_title );
            }else{
                resp = [...resp, ...getHierselectRts(item.inner)];
            }
        });
        return resp;
    }

    if( condition.result_title ){
        let row = elements.find( row => row.result_title === condition.result_title);
        if( !row ){ return false; }
        if( row.type !== condition.element_type ){ return false; }
        if( row.values && condition.model?.values ){
            if( Array.isArray(row.values) ){
                if( row.values.length !== condition.model.values.length ){ return false; }
                let valuesValid = true;
                row.values.map( (value, index) => {
                    if( value.result_title !== condition.model.values[index].result_title ){
                        valuesValid = false;
                    }
                });
                if( !valuesValid ){ return false; }
            }
        }
        if( row.type === 'hierselect' && row.values && Array.isArray(row.values) ){
            if( ['compare_selected', 'specific_value'].includes(condition.method) && condition.model.value !== null ){
                let allRts = getHierselectRts(row.values),
                    conditionRts = [];
                if( condition.method === 'compare_selected' ){
                    conditionRts = condition.model.value.map( item => item.result_title );
                }
                if( condition.method === 'specific_value' ){
                    conditionRts = [condition.model.value];
                }
                if( conditionRts.some( rt => !allRts.includes(rt) ) ){
                    return false;
                }
            }
        }
        if( ['select', 'hierselect'].includes(row.type) && condition.model?.multiline !== undefined ){
            if( !!condition.model?.multiline !== !!row.multiline ){
                return false;
            }
        }
    }
    return true;
}

function isValidRuleConditionValue(condition){
    let resp = true;
    if( condition.model ){
        if( condition.model.value === '' ){
            if( ['input', 'inputset', 'text'].includes(condition.element_type) && !['EQ', 'NE'].includes(condition.operator) ){
                resp = false;
            }
        }
        let numVal = condition.model.value && String(condition.model.value).length ? Number(condition.model.value) : NaN;
        if( isNaN( numVal ) ){
            if( ['input', 'inputset', 'text'].includes(condition.element_type) && !['EQ', 'NE'].includes(condition.operator) ){
                resp = false;
            }
            if( ['range'].includes(condition.element_type) ){
                resp = false;
            }
        }
        if( ['checked_length', 'values_length', 'value_length'].includes(condition.method) && !Number.isInteger(numVal)){
            resp = false;
        }
        if( condition.method === 'playback_percent' ){
            if( isNaN( numVal ) || numVal < 0 || numVal > 100 ){
                resp = false;
            }
        }
        if( condition.method === 'specific_value' && (condition.model.value === '' || condition.model.value === null) ){
            resp = false;
        }
    }
    return resp;
}

function isValidRuleActionValue(action, elements = []){
    let resp = true;
    if( action.model && action.result_title){
        let row = elements.find( row => row.result_title === action.result_title),
            numVal = action.model.value && String(action.model.value).length ? Number(action.model.value) : NaN;
        if( row ){
            if( ['range'].includes(action.element_type) && action.method === 'change_value' ){
                if( isNaN( numVal ) ){
                    resp = false;
                }
                if( numVal < row.min_value || numVal > row.max_value ){
                    resp = false;
                }
            }
            if( action.method === 'change_playback_percent' ){
                if( action.model.playback_percent_min < 0 || action.model.playback_percent_min > 100 ){
                    resp = false;
                }
            }
        }
    }
    return resp;
}

export {
    isPhone,
    isPhoneWith7,
    isValidFio,
    isGreaterThanZero,
    isValidExecutionTime,
    isValidPriority,
    isValidPrice,
    isValidConsensus,
    isValidDate,
    isResidentPaymentAccount,
    getVuelidateScheme,
    isValidHotkeys,
    isUniqueResultTitle,
    isNotReservedResultTitle,
    isValidModerationTotalAnswers,
    isValidIou,
    isValidDiffAnnotator,
    isValidSkills,
    isValidExportFormat,
    isValidRuleCommon,
    isValidRuleConditionValue,
    isValidRuleActionValue
}

<template>
    <icon-template
        :width="24"
        :height="24"
        name="CloseThin"
        >
        <path fill="currentColor" d="M2.1 3.515 3.516 2.1 21.9 20.485 20.485 21.9 2.101 3.515Z"/>
        <path fill="currentColor" d="M3.515 21.9 2.1 20.485 20.485 2.101 21.9 3.515 3.515 21.9Z"/>
    </icon-template>
</template>

<script>
    import iconTemplate from '@/components/icons/iconTemplate';
    export default {
        components: {
            iconTemplate
        }
    }
</script>

<template>

    <div
        class="control control--dropdown"
        :class="{
            'control--active': isVisible,
            'control--disabled': disabled
        }"
        v-click-outside="hideMenu"
        >
        <div class="control__activator" @click="toggleMenu()">
            <slot name="activator"></slot>
        </div>
        <div class="control__wrapper" ref="wrapper" :style="wrapperStyle">
            <div class="control__indicator" :style="indicatorStyle"></div>
            <div class="control__content">
                <slot name="default"></slot>
            </div>
        </div>
    </div>

</template>

<script>

    import { events } from '@/lib/events';
    import ClickOutside from 'vue-click-outside';

    export default {
        name: 'controlDropdown',
        props: {
            disabled: {
                type: Boolean,
                default: false
            }
        },
        directives: {
            ClickOutside
        },
        components: {

        },
        data() {
            return{
                isVisible: false,
                shiftY: 0,
                shiftX: 0
            }
        },
        computed: {
            wrapperStyle(){
                return `transform: translateX(${this.shiftX}px) translateY(${this.shiftY}px);`;
            },
            indicatorStyle(){
                return `transform: translateX(${-this.shiftX}px);`;
            }
        },
        methods: {
            toggleMenu(){
                if( this.disabled ){ return; }
                this.isVisible ? this.hideMenu() : this.showMenu();
            },
            hasParentWithOverflow(node, axis = 0){//0 - x axis, 1 - y axis
                let resp = false;
                if( node.parentNode && node.parentNode.tagName !== 'BODY' ){
                    let style = window.getComputedStyle(node.parentNode),
                        overflow = style.getPropertyValue('overflow');
                    overflow = overflow.split(' ');
                    if( overflow.length === 1 ){//если значение одинаково для обоих осей
                        overflow.push(overflow[0]);
                    }
                    resp = ['hidden', 'clip', 'scroll', 'auto'].includes(overflow[axis]);
                    if( resp === false ){
                        resp = this.hasParentWithOverflow(node.parentNode, axis);
                    }
                }
                return resp;
            },
            showMenu(){
                let viewport = {
                    width: window.innerWidth,
                    height: window.innerHeight
                };
                this.isVisible = true;
                this.$emit('dropdown-open', {});
                this.$nextTick(() => {
                    let box = this.$refs.wrapper.getBoundingClientRect();
                    if( box.x < 0 ){
                        this.shiftX = -box.x;
                    }else if( (box.right > viewport.width) && (box.x >= box.width)  ){
                        if( this.hasParentWithOverflow(this.$refs.wrapper, 0) ){ return; }
                        this.shiftX = -box.width;
                    }
                    if( box.y < 0 ){
                        this.shiftY = -box.y;
                    }else if( (box.bottom > viewport.height) && (box.y >= box.height) ){
                        if( this.hasParentWithOverflow(this.$refs.wrapper, 1) ){ return; }
                        this.shiftY =  -box.height;
                    }
                });
            },
            hideMenu(){
                this.isVisible = false;
                this.$emit('dropdown-close', {});
                this.shiftY = 0;
                this.shiftX = 0;
            },
            evbusCloseAllWindowsHandler(data){
                if( this.isVisible ){
                    this.hideMenu();
                }
            }
        },
        mounted(){
            this.$evbus.$on(events.EV_APP_CLOSE_ALL_WINDOWS, this.evbusCloseAllWindowsHandler);
        },
        beforeDestroy(){
            this.$evbus.$off(events.EV_APP_CLOSE_ALL_WINDOWS, this.evbusCloseAllWindowsHandler);
        }
    }

</script>
<template>
    <icon-template
        :width="32"
        :height="32"
        name="ScrollTop"
        >
        <path fill="currentColor" d="M15.17 24v2.07c0 .514.37.93.828.93.457 0 .827-.416.827-.93V24H15.17ZM15.17 18h1.66v6h-1.66v-6ZM16.828 18V8.56l5.76 6.471a.765.765 0 0 0 1.17 0 1.013 1.013 0 0 0 0-1.315L16 5l-7.758 8.716a1.013 1.013 0 0 0 0 1.315.765.765 0 0 0 1.17 0l5.76-6.471V18h1.656Z"/>
          <circle cx="16" cy="16" r="15.5" stroke="currentColor" fill="transparent"/>
    </icon-template>
</template>

<script>
    import iconTemplate from '@/components/icons/iconTemplate';
    export default {
        components: {
            iconTemplate
        }
    }
</script>

<template>
    <div
        v-if="isVisible"
        class="modal modal--visible modal--close-locked"
        >
        <div class="modal__overlay" @click.self="onPopupClose()">
            <div class="modal__container">
                <div class="modal__header">
                    {{ $t('popups.law_docs') }}
                </div>
                <div class="wrapper">
                    <div class="modal__main scroll scroll--thin d-flex flex-column">

                        <template v-if="currentDoc">
                            <div class="mb-2">
                                <a
                                    class="btn btn--link btn--sm link link--dotted mr-3"
                                    :href="currentDoc.link"
                                    target="_blank"
                                    >
                                    <span class="btn__text link__text h3 text--left">
                                        {{ currentDoc.name }}
                                    </span>
                                </a>
                            </div>
                            <iframe
                                :src="currentDoc.link"
                                frameborder="0"
                                class="w-100 flex-grow-1"
                                >
                            </iframe>

                        </template>

                    </div>
                    <div class="modal__footer">
                        <div class="d-flex align-items-center flex-wrap justify-content-between w-100">
                            <div class="d-flex align-items-center flex-wrap" v-if="docs.length > 1">
                                <button
                                    class="btn btn--outline-primary btn--sm my-1 mr-3"
                                    :disabled="currentIndex === 0"
                                    :title="$t('btns.back')"
                                    @click="currentIndex--"
                                    >
                                    {{ $t('btns.back') }}
                                </button>
                                <button
                                    class="btn btn--outline-primary btn--sm my-1 mr-3"
                                    :disabled="currentIndex === docs.length - 1"
                                    :title="$t('btns.next')"
                                    @click="currentIndex++"
                                    >
                                    {{ $t('btns.next') }}
                                </button>
                            </div>
                            <div class="d-flex align-items-center flex-wrap ml-auto">
                                <button
                                    class="btn btn--warning btn--sm my-1 mr-3"
                                    :disabled="isSaving"
                                    :title="$t('btns.refuse')"
                                    @click="refusedUserDocs()"
                                    >
                                    {{ $t('btns.refuse') }}
                                </button>
                                <button
                                    class="btn btn--primary btn--sm my-1"
                                    :disabled="isSaving"
                                    :title="$t('btns.sign')"
                                    @click="saveUserDocs()"
                                    >
                                    {{ $t('btns.sign') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import { request } from '@/lib/ajax';
    import { events } from '@/lib/events';
    import mixinPopup from '@/mixins/mixinPopup';

    export default {
        name: 'ElPopupLawDocs',
        mixins: [
            mixinPopup
        ],
        components: {

        },
        data() {
            return {
                pollingDocs: null,
                docs: [],
                currentIndex: 0,
                isSaving: false
            }
        },
        computed: {
            currentDoc(){
                return this.docs.length ? this.docs[this.currentIndex] : null;
            }
        },
        methods:{
            checkUserDocs(){
                let is404Page = this.$route.name === undefined;
                if ( this.$store.getters.isLoggedIn && !is404Page) {
                    request({
                        url: 'api/v1/user/get-law-docs',
                        method: 'GET'
                    }).then( resp => {
                        this.currentIndex = 0;
                        this.docs = resp.data.docs;
                        let user = {... this.$store.getters.user};
                        user.is_authorized = !!parseInt(resp.data.is_authorized);
                        this.$store.dispatch('changeUserInfo', {
                            user: user
                        });
                        if( this.docs.length ){
                            this.onPopupShow();
                        }
                    });
                }
            },
            saveUserDocs(){
                this.isSaving = true;
                request({
                    url: 'api/v1/user/set-law-docs-accepted',
                    method: 'POST',
                    data: {
                        id: this.currentDoc.id
                    }
                }).then( resp => {
                    let user = {... this.$store.getters.user};
                    user.status_id = resp.data.status_id;
                    this.$store.dispatch('changeUserInfo', {
                        user: user
                    });
                    if( !this.$store.getters.userRefuseLawDocs ){
                        if( this.$route.name === 'jobs' ){
                            this.$evbus.$emit(events.EV_PAGE_JOBS_LOAD_JOB_LIST);//обновляем список заданий
                        }
                    }

                    this.docs.splice(this.currentIndex, 1);
                    this.currentIndex = 0;
                    if( !this.docs.length ){
                        this.onPopupClose();
                    }
                }).finally(() => {
                    this.isSaving = false;
                });
            },
            refusedUserDocs(){
                if( !confirm( this.$t('lbls.confirm_law_docs_refuse') ) ) {
                    return;
                }
                this.isSaving = true;
                request({
                    url: 'api/v1/user/refused-to-sign-doc',
                    method: 'POST'
                }).then( resp => {
                    let user = {... this.$store.getters.user};
                    user.status_id = resp.data.status_id;
                    this.$store.dispatch('changeUserInfo', {
                        user: user
                    });

                    this.docs.splice(this.currentIndex, 1);
                    this.currentIndex = 0;
                    if( !this.docs.length ){
                        this.onPopupClose();
                    }
                }).finally(() => {
                    this.isSaving = false;
                });
            },
            pollDocs(immediate = true) {
                if( this.$store.getters.userRefuseLawDocs ){ return; }
                this.stopPolling();
                if( immediate ){
                    this.checkUserDocs();
                }
                this.pollingDocs = setInterval( () => {
                    this.checkUserDocs();
                }, 1000 * 60 * this.$store.state.project.updateDocsMin);
            },
            stopPolling(){
                clearInterval(this.pollingDocs);
            },
            onPopupShow(){
                this.stopPolling();
                this.showPopup();
            },
            onPopupClose(){
                this.pollDocs(true);
                this.closePopup();
            },
            evbusLoadDocsHandler(data){
                this.checkUserDocs();
            },
            evbusPageVisibilityhandler(isVisible){
                isVisible ? this.pollDocs(false) : this.stopPolling();
            }
        },
        mounted(){
            this.pollDocs(true);
            this.$evbus.$on(events.EV_LOAD_LAW_DOCS, this.evbusLoadDocsHandler);
            this.$evbus.$on(events.EV_PAGE_VISIBILITY_CHANGE, this.evbusPageVisibilityhandler);
        },
        beforeDestroy(){
            this.stopPolling();
            this.$evbus.$off(events.EV_LOAD_LAW_DOCS, this.evbusLoadDocsHandler);
            this.$evbus.$off(events.EV_APP_OPEN_POPUP, this.evbusOpenPopupHandler);
            this.$evbus.$off(events.EV_APP_CLOSE_POPUP, this.evbusClosePopupHandler);
            this.$evbus.$off(events.EV_PAGE_VISIBILITY_CHANGE, this.evbusPageVisibilityhandler);
        }
    }

</script>

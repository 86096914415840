function numericOnlyOnKeyDown(evt){
    let pressedKey = evt.key.toUpperCase(),
        allowedKeys = ['DELETE', 'BACKSPACE', 'TAB', 'ESCAPE', 'ENTER', 'END', 'HOME', 'ARROWLEFT', 'ARROWRIGHT', '.'];
    //allow some special keys
    if ( allowedKeys.includes(pressedKey) ){
        return;
    }
    // allow some key bindings
    if ( (evt.ctrlKey === true || evt.metaKey === true) && ['A', 'Ф', 'C', 'С', 'X', 'Ч', 'V', 'М'].includes(pressedKey)){
        return;
    }
    if ( (evt.ctrlKey === true || evt.shiftKey === true) && pressedKey === 'INSERT'){
        return;
    }
    // Ensure that it is not a number and stop the keypress
    if ( !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(pressedKey) ) {
        evt.preventDefault();
    }
}

function numericOnlyOnPaste(evt){//allow paste only digits
    let clipboardData = evt.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData('Text');
    let pastedDataCleared = pastedData.replace(/\D+/g, '');
    if (pastedData.length !== pastedDataCleared.length){
        evt.preventDefault();
        document.execCommand("insertText", false, pastedDataCleared);
    }
}

export default {
    bind(el, binding, vnode) {
        el.addEventListener('keydown', numericOnlyOnKeyDown);
        el.addEventListener('paste', numericOnlyOnPaste);
    },
    unbind(el, binding, vnode){
        el.removeEventListener('keydown', numericOnlyOnKeyDown);
        el.removeEventListener('paste', numericOnlyOnPaste);
    }
}
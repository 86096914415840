function leftPad(what, desiredLen, symbol) {

    what = String(what);
    symbol = symbol ? String(symbol).charAt(0) : '0';
    desiredLen = Number(desiredLen);

    while (what.length < desiredLen) {
        what = symbol + what;
    }

    return what;
}

function rightPad(what, desiredLen, symbol) {

    what = String(what);
    symbol = symbol ? String(symbol).charAt(0) : '0';
    desiredLen = Number(desiredLen);

    while (what.length < desiredLen) {
        what = what + symbol;
    }

    return what;
}

function roundTo(v, zeroes) {
    zeroes = zeroes || 2;

    let _to = 10;
    for (let i = 1; i < zeroes; i++) {
        _to = _to * 10;
    }

    return Math.round(v * _to) / _to;
}

function formatBigNumber(v) {
    v = Number(v);

    let
        isNeg = (v < 0),
        vPos = Math.abs(v),
        intPart = Math.trunc(vPos),
        fracPart = roundTo(vPos % 1, 2),
        res = (fracPart === 0) ? '' : ('' + fracPart).replace('0.', '.'),
        sIntPart = '' + intPart;

    if (intPart.length < 4) {
        return sIntPart + res;
    }

    let c = 1;
    for (let i = sIntPart.length - 1; i >= 0; i--) {
        res = sIntPart[i] + res;
        c++;
        if (c > 3 && i > 0) {
            c = 1;
            res = ' ' + res; // 1/4 space
        }
    }

    res = ((isNeg) ? '- ' : '') + res;

    res = res.replace('.', ',');

    return res;
}

function getDigits(val) {
    return (val || '').replace(/\D+/g, '');
}

function leadingZero(value, minLength = 2){
    value = value.toString();
    while (value.length < minLength) {
        value = `0${value}`;
    }
    return value;
}

function outputTimeFormat(value, formatString, i18n, resultFunc){
    let result = {},
        format = {
            origin: formatString,
            keys: [],
            minLength: {}
        },
        params = [
            { key: 'd', secVal: 1*60*60*24}, //days
            { key: 'h', secVal: 1*60*60}, //hours
            { key: 'm', secVal: 1*60}, //minutes
            { key: 's', secVal: 1}, //seconds
            { key: 't', secVal: 0.001} //milliseconds(thousandths)
        ];
    formatString.split(':').map( part => {
        format.keys.push( part[0] );
        format.minLength[part[0]] = part.length;
    });
    params.map( param => {
        if( format.keys.includes( param.key ) ){
            let paramVal = parseInt(value/param.secVal, 10);
            result[param.key] = paramVal;
            value = value > 0 ? value - paramVal*param.secVal : value;
        }
    });
    return resultFunc(params, format, result, value, i18n);
}

function timeWithShortLabels(params, format, result, remainder, i18n){
    let resp = [],
        labelsByKey = i18n;
    params.map( param => {
        let minLength = format.minLength[param.key];
        if( format.keys.includes(param.key) ){
            resp.push( `${leadingZero(result[param.key], minLength)}${labelsByKey[param.key]}`);
        }
    });
    return resp.join(' ');
}

function timeWithoutLabels(params, format, result, remainder, i18n){
    let resp = [],
        append = '';
    params.map( param => {
        let minLength = format.minLength[param.key];
        if( format.keys.includes(param.key) ){
            if( param.key === 't' ){
                append = `.${result[param.key]}`;
            }else{
                resp.push( leadingZero(`${result[param.key]}`, minLength) )
            }
        }
    });
    return `${resp.join(':')}${append}`;
}

function declOfNum(num, labels) {
    let resp,
        count = num % 100;
    if (count >= 5 && count <= 20) {
        resp = labels[0];
    } else {
        count = count % 10;
        if (count == 1) {
            resp = labels[1];
        } else if (count >= 2 && count <= 4) {
            resp = labels[2];
        } else {
            resp = labels[0];
        }
    }
    return resp;
}

function formatDuration(seconds, options) {

    options = options || {};
    options.shorten = !!(options.shorten || false);
    options.withHours = !!(options.withHours || false);

    seconds = Number(seconds);

    let
        hours = 0,
        minutes = 0,
        secs = seconds % 60,
        fh = '',
        fm = '',
        fs = leftPad(secs, 2),
        shortLen = options.shorten ? 1 : 2;

    if (options.withHours) {
        hours = Math.floor(seconds / 3600);
        minutes = Math.floor(seconds % 3600 / 60);
        fh = leftPad(hours, shortLen);
        fm = leftPad(minutes, 2);
    } else {
        minutes = Math.floor(seconds / 60);
        fm = leftPad(minutes, shortLen);
    }

    return (fh ? fh + ':' : '') + fm + ':' + fs;
}

function formatMoney(value) {
    return formatBigNumber(value);
}

function formatPhone(phone) {
    phone = getDigits(phone);
    phone = rightPad(phone, 11, ' ');
    return '+' + phone.charAt(0) + ' ('+ phone.substr(1,3) +') ' + phone.substr(4,3) + '-' + phone.substr(7,2) + '-' + phone.substr(9,2);
}

function getFio(f, l, p) {
    let res = l || '';
    f = f || '';
    p = p || '';

    if (f.length) {
        res += ' ' + f.charAt(0).toUpperCase() + '.';
    }
    if (p.length) {
        res += ' ' + p.charAt(0).toUpperCase() + '.';
    }

    return res;
}

function timeFormat(value, formatString = 'hh:mm:ss', addLabels = true, i18n){//value in seconds format: dd:hh:mm:ss:ttt
    value = parseFloat(value);
    let outputFunc = addLabels ? timeWithShortLabels : timeWithoutLabels;
    return outputTimeFormat(value, formatString, i18n, outputFunc);
}

function toFixedTrunc(number, digitsCount){//обрезать число до digitsCount знаков без округления
    return Math.trunc(number*Math.pow(10, digitsCount))/Math.pow(10, digitsCount);
}

function i18nHotkeyByAction(action, row){
    let i18nKey = `hotkeys.${action.replaceAll('-', '_')}`;
    if( row.type === 'videoannotator' && ['add_tag'].includes(action) ){
        i18nKey = `${i18nKey}_${row.selection_type}`;
    }
    return i18nKey;
}

export {
    formatDuration,
    leftPad,
    rightPad,
    formatMoney,
    formatPhone,
    getDigits,
    getFio,
    timeFormat,
    declOfNum,
    toFixedTrunc,
    i18nHotkeyByAction
}
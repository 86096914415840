<template>
    <header class="page__header header" :class="{'header--menu-open': menuOpen}">
        <div class="page__wrapper">

            <router-link
                class="header__logo"
                :title="$store.state.project.title"
                :to="{name: 'index'}"
                >
                <icon-logo></icon-logo>
                <span class="text--bold">
                    {{ $store.state.project.title }}
                </span>
            </router-link>

            <div class="header__menu scroll scroll--thin">
                <!-- user menu -->
                <div class="nav nav--header" v-if="$store.getters.isLoggedIn">
                    <el-header-menu
                        data-v-step="main-menu"
                        :menu-items="menu"
                        :cnt="notificationsCount"
                        @close-menu="closeMenu"
                        >
                    </el-header-menu>
                </div>
                <div class="header__right">

                    <div class="control--select control--select-lang mr-3" v-if="$store.getters.isDeveloper">
                        <select
                            class="control__input"
                            v-model="i18n.selected"
                            @change="onLanguageChange()"
                            >
                            <option
                                v-for="(lang, index) in i18n.options"
                                :key="`lang_${index}`"
                                :value="lang"
                                >
                                {{ i18n.labels[lang] }}
                            </option>
                        </select>
                    </div>

                    <!-- user -->
                    <div
                        v-if="$store.getters.isLoggedIn"
                        class="user"
                        data-v-step="profile-menu"
                        >
                        <div class="user__name text-nowrap">
                            {{ $store.getters.fioFormatted }}
                        </div>
                        <control-dropdown class="control--dropdown-header">
                            <template v-slot:activator>
                                <div class="user__pic">
                                    <img :src="$store.getters.userLogo" :alt="$store.getters.fioFormatted">
                                </div>
                            </template>
                            <el-header-menu
                                class="menu--profile"
                                :menu-items="menu"
                                :cnt="notificationsCount"
                                @close-menu="closeMenu"
                                >
                                <template v-slot:menu-append>
                                    <li class="menu__item menu__item--footer">
                                        <div class="nav__item">
                                            <a href="#" class="link" @click.prevent="onClickExit">
                                                {{ $t('btns.log_out') }}
                                            </a>
                                        </div>
                                    </li>
                                </template>
                            </el-header-menu>
                        </control-dropdown>
                    </div>
                </div>

                <!-- user mobile menu  -->
                <div class="nav nav--header nav--user" v-if="$store.getters.isLoggedIn">
                    <el-header-menu
                        :menu-items="menu"
                        :cnt="notificationsCount"
                        @close-menu="closeMenu"
                        >
                        <template v-slot:menu-append>
                            <li class="menu__item menu__item--footer">
                                <div class="nav__item">
                                    <a href="#" class="link" @click.prevent="onClickExit">
                                        {{ $t('btns.log_out') }}
                                    </a>
                                </div>
                            </li>
                        </template>
                    </el-header-menu>
                </div>

            </div>

        <div
            v-if="$store.getters.isLoggedIn"
            class="header__menu-toggle"
            data-v-step="main-menu-mobile"
            >
            <button class="btn btn--action" @click="toggleMenu()">
                <icon-menu-open />
                <icon-menu-close />
            </button>
        </div>

        </div>

    </header>
</template>

<script>

    import { libAuth } from '@/lib/libAuth';
    import { events } from '@/lib/events';
    import { request } from '@/lib/ajax';
    import iconLogo from '@/components/icons/iconLogo';
    import iconMenuOpen from '@/components/icons/iconMenuOpen';
    import iconMenuClose from '@/components/icons/iconMenuClose';
    import controlDropdown from '@/components/controls/controlDropdown';
    import ElHeaderMenu from '@/components/ElHeaderMenu';
    import ElToastMessage from '@/components/ElToastMessage';

    export default {
        name: 'ElHeader',
        props: {},
        components: {
            iconLogo,
            iconMenuOpen,
            iconMenuClose,
            controlDropdown,
            ElHeaderMenu,
            ElToastMessage
        },
        data() {
            return {
                menuOpen: false,
                menu: [],
                notificationsCount: 0,
                pollingNotifications: null,
                i18n: {
                    options: ['ru', 'en'],
                    selected: this.$store.getters.getLang,
                    labels: {
                        ru: 'Русский',
                        en: 'English'
                    }
                }
            };
        },
        computed: {

        },
        methods: {
            onClickExit() {
                libAuth.logout().finally(() => {
                    this.$evbus.$emit(events.EV_LOGOUT);
                });
            },
            toggleMenu() {
                this.menuOpen = !this.menuOpen;
                this.$evbus.$emit(events.EV_APP_TOGGLE_PAGE_SCROLLBAR, !this.menuOpen);
            },
            closeMenu() {
                this.menuOpen = false;
                this.$evbus.$emit(events.EV_APP_TOGGLE_PAGE_SCROLLBAR, !this.menuOpen);
            },
            checkNotifications(showNotify = true){
                request({
                    url: 'api/v1/notifications/check-notification',
                    method: 'GET'
                }).then( resp => {
                    this.notificationsCount = resp.data.cnt;
                    let idsCount = resp.data.topics.length,
                        href = '',
                        showAlert = resp.data.show_alert;
                    if( idsCount && showNotify && showAlert){
                        let data = idsCount > 1 ? {name: 'messenger'} : {name: 'message', params: {id: resp.data.topics[0]} }
                        href = data.name === this.$route.name ? '' : this.$router.resolve(data).href;
                        this.$toast.success(
                            {
                                component: ElToastMessage,
                                props: {
                                    title: this.$t('lbls.new_message'),
                                    href: href,
                                    btnText: this.$t('btns.details')
                                }
                            },
                            {
                                timeout: 10000
                            }
                        );
                    }
                });
            },
            pollNotifications(immediate = true) {
                this.stopPolling();
                if( immediate ){
                    this.checkNotifications();
                }
                this.pollingNotifications = setInterval( () => {
                    this.checkNotifications();
                }, 1000 * 60 * this.$store.state.project.updateNotificationsMin);
            },
            stopPolling() {
                clearInterval(this.pollingNotifications);
            },
            getDefaultMenuLinks(){
                request({
                    url: 'api/v1/default/menu',
                    method: 'GET'
                }).then( resp => {
                    this.menu = resp;
                });
            },
            currentRouteAncestor(name) {
                let paths = Array.isArray(name) ? name : [name];
                return paths.some(path => {
                    return this.$route.path.indexOf(path) === 0 // current path starts with this path string
                })
            },
            onLanguageChange(emitEvent = true, lang = null) {
                if( !lang ){ lang = this.i18n.selected; }
                this.$i18n.locale = lang;
                this.$store.commit('setLang', lang);
                this.$store.dispatch('saveUserPreferencies', { lang: lang });
                document.querySelector('html').setAttribute('lang', lang);
                if( emitEvent ){
                    this.$evbus.$emit(events.EV_APP_TOGGLE_LANG, lang);
                }
            },
            evbusLoginHandler(data){
                this.getDefaultMenuLinks();
                this.pollNotifications(true);
            },
            evbusLogoutHandler(data){
                this.stopPolling();
            },
            evbusCheckNotificationsHandler(data){
                this.checkNotifications(false);
            },
            evbusLanghandler(data){
                this.onLanguageChange(false, data.lang);
            },
            evbusPageVisibilityhandler(isVisible){
                isVisible ? this.pollNotifications(false) : this.stopPolling();
            }
        },
        mounted() {
            if ( this.$store.getters.isLoggedIn ) {
                this.pollNotifications(true);
                this.getDefaultMenuLinks();
            }
            this.$evbus.$on(events.EV_LOGIN, this.evbusLoginHandler);
            this.$evbus.$on(events.EV_LOGOUT, this.evbusLogoutHandler);
            this.$evbus.$on(events.EV_APP_CHECK_NOTIFICATIONS, this.evbusCheckNotificationsHandler);
            this.$evbus.$on(events.EV_APP_TOGGLE_LANG, this.evbusLanghandler);
            this.$evbus.$on(events.EV_PAGE_VISIBILITY_CHANGE, this.evbusPageVisibilityhandler);
        },
        destroyed() {
            this.stopPolling();
            this.$evbus.$off(events.EV_LOGIN, this.evbusLoginHandler);
            this.$evbus.$off(events.EV_LOGOUT, this.evbusLogoutHandler);
            this.$evbus.$off(events.EV_APP_CHECK_NOTIFICATIONS, this.evbusCheckNotificationsHandler);
            this.$evbus.$off(events.EV_APP_TOGGLE_LANG, this.evbusLanghandler);
            this.$evbus.$off(events.EV_PAGE_VISIBILITY_CHANGE, this.evbusPageVisibilityhandler);
        }
    }
</script>


<template>
    <div class="banner banner--law-docs-refuced" :class="{'i18n--loading': i18nLoading }">
        <div class="page__wrapper">
            <div class="banner__content">
                <p class="text">
                    {{ $t('text[0]') }}
                </p>
                <button
                    class="btn btn--sm text-nowrap"
                    :title="$t('btns.sign')"
                    @click="loadLawDocs()"
                    >
                    {{ $t('btns.sign') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import { events } from '@/lib/events';
    import mixinI18n from '@/mixins/mixinI18n.js';
    import { defaultLang } from '@/i18n/components/banners/ElBannerLawDocsRefused';

    export default {
        name: 'ElBannerLawDocsRefused',
        mixins: [
            mixinI18n
        ],
        components: {

        },
        data() {
            return {

            }
        },
        i18n: {
            messages: defaultLang
        },
        methods: {
            loadLawDocs(){
                this.$evbus.$emit(events.EV_LOAD_LAW_DOCS);
            },
            async loadI18nFile(lang){
                let loadModule = () => import(`@/i18n/components/banners/ElBannerLawDocsRefused/${lang}.js`);
                await loadModule().then(module => {
                    this.$i18n.setLocaleMessage(lang, module.default);
                });
            }
        },
        mounted() {

        }
    }
</script>
<template>
    <div class="banner banner--not-authorized-customer" :class="{ 'i18n--loading': i18nLoading }">
        <div class="page__wrapper">
            <div class="banner__content">
                <p class="text">
                    {{ $t('text[0]') }}.<br/>
                    {{ $t('text[1]') }} <a class="link link--primary" target="_blank" :href="`${$store.state.project.sandboxUrl}api/v1/auth/create-account?access_token=${$store.state.auth.accessToken}`">{{ $t('text[2]') }}</a>, {{ $t('text[3]') }}.
                </p>
                <button
                    class="btn btn--sm text-nowrap"
                    :title="$t('btns.details')"
                    @click="$openPopup('missed-requisites')"
                    >
                    {{ $t('btns.details') }}
                </button>
            </div>
        </div>

        <el-popup-missed-requisites :name="'popup-missed-requisites'"></el-popup-missed-requisites>

    </div>
</template>

<script>

    import mixinI18n from '@/mixins/mixinI18n.js';
    import { defaultLang } from '@/i18n/components/banners/ElBannerNotAuthorizedCustomer';
    import ElPopupMissedRequisites from '@/components/popups/ElPopupMissedRequisites';

    export default {
        name: 'ElBannerNotAuthorizedCustomer',
        mixins: [
            mixinI18n
        ],
        components: {
            ElPopupMissedRequisites
        },
        data() {
            return {

            }
        },
        i18n: {
            messages: defaultLang
        },
        methods: {
            async loadI18nFile(lang){
                let loadModule = () => import(`@/i18n/components/banners/ElBannerNotAuthorizedCustomer/${lang}.js`);
                await loadModule().then(module => {
                    this.$i18n.setLocaleMessage(lang, module.default);
                });
            }
        },
        mounted() {

        }
    }
</script>

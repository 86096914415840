<template>
    <div class="preloader preloader--circle">
        <div class="preloader__icon">
            <component :is="`icon-${icon}`"></component>
        </div>
        <span class="preloader__caption">
            {{ preloaderText }}
        </span>
    </div>
</template>

<script>
    import iconPreloaderCircle from '@/components/icons/iconPreloaderCircle';
    import iconPreloaderInfo from '@/components/icons/iconPreloaderInfo';

    export default {
        name: 'ElPreloader',
        components: {
            iconPreloaderCircle,
            iconPreloaderInfo
        },
        props: {
            icon: {
                type: String,
                default: 'preloader-circle'
            },
            text: {
                type: String,
                default: null
            }
        },
        computed: {
            preloaderText(){
                return this.text !== null ? this.text : this.$t('lbls.loading')
            }
        }
    }
</script>

import store from '@/store/store';
import { libAuth } from '@/lib/libAuth';
import { events } from '@/lib/events';

/**
 * Ajax request wrapper.
 * @param opts - url, data, notifyOnError:
 *  url - relative API path
 *  data - object with post data
 *  notifyOnError - notify with error message. Default true.
 * @returns Promise
 */

function makeRequest(opts, headers, body) {
    return new Promise((resolve, reject) => {

        function onError(error, errorMessageKey) {
            if (opts.notifyOnError) {
                window.dispatchEvent(new CustomEvent(events.EV_APP_SHOW_TOAST, {
                    detail: {
                        type: 'error',
                        i18n: true,
                        msgKey: errorMessageKey
                    }
                }));
            }
            reject(error);
        }

        let isV2Api = opts.url.indexOf('api/v2/') > -1;

        fetch(
            opts.url,
            {
                method: opts.method,
                mode: 'cors', // cross domain
                headers: headers,
                body: opts.method === 'GET' ? null : body,
                cache: opts.cache || 'no-store',
                credentials: 'include' // with cookie
            }
        ).then( response => {
            if (response.ok) {
                if (opts.response_type === 'text' ){
                    response.text().then( resp => {
                        resolve(resp);
                    });
                    return;
                }
                if (opts.response_type === 'blob') {
                    response.blob().then(resp => {
                        resolve(resp);
                    });
                    return;
                }
                if (opts.response_type === 'formData') {
                    response.formData().then(resp => {
                        resolve(resp);
                    });
                    return;
                }
                if (opts.response_type === 'arrayBuffer') {
                    response.arrayBuffer().then(resp => {
                        resolve(resp);
                    });
                    return;
                }
                response.json()
                    .then( resp => {
                        // в новом api у ошибок response.ok, а код ошибки в JSON поле status
                        if (resp.status && resp.status !== 200 && !isV2Api){
                            if (resp.data && resp.data.message) {
                                onError(resp, resp.data.message);
                            } else {
                                onError(resp, 'errors.network_json_status');
                            }
                        }else{
                            resolve(resp);
                        }
                    })
                    .catch( resp => {
                        onError(resp, 'errors.network_json_parse');
                    });
            } else {
                response.json()
                    .then( resp => {
                        if (resp.message) {
                            onError(resp, resp.message);
                        } else {
                            onError(resp, 'errors.network_not_ok_no_message');
                        }
                    }).catch( resp => {
                        onError(resp, 'errors.network_not_ok_parse');
                    });
            }
        }).catch( resp =>  {
            onError( resp, 'errors.network_connect');
        });

    });
}

function waitForTokens(){
    return new Promise((resolve, reject) => {
        let tokensInterval = setInterval(() => {
            if ( !store.getters.updatingTokens ){
                clearInterval(tokensInterval);
                resolve(true);
            }
        }, 100);
    });
}

export function request(opts) {

    let apiSettings = store.getters.apiSettings;

    opts.data = opts.data || {};
    opts.method = opts.method || 'POST'; //set POST by default
    opts.data.apiver = apiSettings.version;
    opts.data.locale = store.getters.getLang;

    if (!opts.hasOwnProperty('notifyOnError')) {
        opts.notifyOnError = true;
    }

    opts.url = opts.base ? `${opts.base}${opts.url}` : `${apiSettings.base}${opts.url}`

    if (opts.method === 'GET' && opts.data ){ // add POST styled params to url
        opts.url += '?';
        let getParams = [];
        Object.keys(opts.data).map( key => {
            if (opts.data[key] !== undefined ){
                getParams.push(`${key}=${opts.data[key]}`);
            }
        });
        opts.url += getParams.join('&');
    }

    let headers = {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json'
    }

    let body = JSON.stringify(opts.data);

    if( opts.interface === 'formData' ){
        body = new FormData();
        for( var key in opts.data ){
            if (opts.data.hasOwnProperty(key) ){
                body.append(key, opts.data[key]);
            }
        }
        delete headers['Content-Type'];
    }

    if( opts.noauth ){
        return makeRequest(opts, headers, body);
    }else{
        headers['Authorization'] = store.getters.getBearer;

        if ( store.getters.updatingTokens ){
            return new Promise((resolve, reject) => {
                waitForTokens().then(ready => {
                    headers['Authorization'] = store.getters.getBearer;
                    resolve(makeRequest(opts, headers, body));
                }).catch(error => {
                    window.dispatchEvent(new CustomEvent(events.EV_APP_SHOW_TOAST, {
                        detail: {
                            type: 'error',
                            msg: error
                        }
                    }));
                })
            })
        }else{
            // if access token expired and refreshToken is exist >> go to API and get new access token
            if (libAuth.isAccessTokenExpired() && libAuth.getRefreshToken()) {
                return new Promise((resolve,reject) => {
                    libAuth.refreshTokens().then(success => {
                        headers['Authorization'] = store.getters.getBearer;
                        resolve( makeRequest(opts, headers, body) );
                    }).catch(error => {
                        console.log(error);
                    })
                })
            } else {
                return makeRequest(opts, headers, body);
            }
        }
    }
}

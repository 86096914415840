export const events = {

    EV_BANNER_SELF_EMPLOYED_CLOSE: 'banner-self-employed-close',

    EV_ACTIONS_HISTORY_ADD: 'actions-history-add',

    EV_ACTIONS_HISTORY_UNDO: 'actions-history-undo',

    EV_ACTIONS_HISTORY_REDO: 'actions-history-redo',

    EV_ACTIONS_HISTORY_TRIGGER_UNDO: 'actions-history-trigger-undo',

    EV_LOGIN: 'login',

    EV_LOGOUT: 'logout',

    EV_LOAD_LAW_DOCS: 'load-law-docs',

    EV_PAGE_JOBS_LOAD_JOB_LIST: 'load-job-list',

    EV_PAGE_TASK_SAVE_RESULT: 'task-save-result',

    EV_PAGE_TASK_TOGGLE_BATCH: 'task-toggle-batch',

    EV_PAGE_VISIBILITY_CHANGE: 'page-visibility-change',

    EV_MEDIA_START_PLAYING: 'media-start-playing',

    EV_APP_CHECK_NOTIFICATIONS: 'check-notifications',

    EV_APP_TOGGLE_PAGE_SCROLLBAR: 'toggle-page-scrollbar',

    EV_APP_OPEN_POPUP: 'open-popup',

    EV_APP_CLOSE_POPUP: 'close-popup',

    EV_APP_CLOSE_ALL_WINDOWS: 'close-all-windows',

    EV_APP_PAGE_PAGEN_RESET: 'page-pagen-reset',

    EV_SKILLS_UPLOAD_FILE: 'skills-upload-file',

    EV_APP_TOGGLE_LANG: 'toggle-lang',

    EV_APP_SHOW_TOAST: 'show-toast',

    EV_CONSTRUCTOR_PREVIEW_CLOSE: 'constructor-preview-close'

}

<template>
    <icon-template
        :width="24"
        :height="24"
        name="Yokassa"
        >
            <defs>
                <linearGradient id="flK536sUDo" x1="3.443" x2="3.443" y1="5.921" y2="18.294" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0160D1"/>
                    <stop offset="1" stop-color="#00479C"/>
                </linearGradient>
                <linearGradient id="x4KRJ3KcAi" x1="3.443" x2="3.443" y1="5.921" y2="18.294" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0160D1"/>
                    <stop offset="1" stop-color="#00479C"/>
                </linearGradient>
            </defs>
            <path fill="url(#flK536sUDo)" d="M15.44 3.5c-4.734 0-8.5 3.82-8.5 8.5 0 4.734 3.82 8.5 8.5 8.5s8.5-3.82 8.5-8.5-3.82-8.5-8.5-8.5Zm0 11.62c-1.721 0-3.174-1.452-3.174-3.174 0-1.721 1.453-3.174 3.174-3.174 1.722 0 3.175 1.453 3.175 3.174-.054 1.776-1.453 3.174-3.175 3.174Z"/>
            <path fill="url(#x4KRJ3KcAi)" d="M6.886 5.92v12.374H3.873L0 5.921h6.886Z"/>
    </icon-template>
</template>

<script>
    import iconTemplate from '@/components/icons/iconTemplate';
    export default {
        components: {
            iconTemplate
        }
    }
</script>

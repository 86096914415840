<template>
    <icon-template
        :width="24"
        :height="24"
        name="PreloaderInfo"
        >
        <path fill="transparent" stroke="currentColor"  stroke-width="2" d="M21 12a9 9 0 1 1-18.002-.002A9 9 0 0 1 21 12Z"/>
        <path fill="currentColor"  d="M11 11a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0v-5ZM13 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"/>
    </icon-template>
</template>

<script>
    import iconTemplate from '@/components/icons/iconTemplate';
    export default {
        components: {
            iconTemplate
        }
    }
</script>

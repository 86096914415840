import { events } from '@/lib/events';
import iconCloseThin from '@/components/icons/iconCloseThin';

export default {
    components: {
        iconCloseThin
    },
    props: {
        name: {
            type: String,
            default: 'default-popup-name'
        },
        ignoreEvbusClose: {
            type: Boolean,
            default: false
        },
        renderInvisibleContent: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isVisible: false
        }
    },
    watch: {

    },
    computed: {
        hasFooter() {
            return !!this.$slots.footer || !!this.$scopedSlots.footer;
        }
    },
    methods: {
        closePopup(data = {}) {
            this.isVisible = false;
            this.$evbus.$emit(events.EV_APP_TOGGLE_PAGE_SCROLLBAR, true);
            this.$emit('popup-close', data);
        },
        showPopup(data = {}) {
            this.isVisible = true;
            this.$evbus.$emit(events.EV_APP_TOGGLE_PAGE_SCROLLBAR, false);
            this.$emit('popup-open', data);
        },
        onPopupShow(){
            this.showPopup();
        },
        onPopupClose(){
            this.closePopup();
        },
        evbusOpenPopupHandler(data){
            if (data.name === this.name) {
                this.onPopupShow(data);
            }
        },
        evbusClosePopupHandler(data){
            if (data.name === this.name) {
                this.onPopupClose(data);
            }
        },
        evbusCloseAllWindowsHandler(data){
            if (this.isVisible && !this.ignoreEvbusClose) {
                this.onPopupClose();
            }
        },
        onPopupMounted() {
            this.$evbus.$on(events.EV_APP_OPEN_POPUP, this.evbusOpenPopupHandler);
            this.$evbus.$on(events.EV_APP_CLOSE_POPUP, this.evbusClosePopupHandler);
            this.$evbus.$on(events.EV_APP_CLOSE_ALL_WINDOWS, this.evbusCloseAllWindowsHandler);
        },
        onDocumentKeyUp(evt){
            if( evt.keyCode == 27 && this.isVisible ){//esc
                this.onPopupClose();
            }
        },
        handleHotkeys(addEvents = false) {
            if (addEvents) {
                document.addEventListener('keyup', this.onDocumentKeyUp);
            }else{
                document.removeEventListener('keyup', this.onDocumentKeyUp);
            }
        }
    },
    mounted() {
        this.handleHotkeys(true);
        this.onPopupMounted();
    },
    beforeDestroy(){
        this.handleHotkeys(false);
        this.$evbus.$off(events.EV_APP_OPEN_POPUP, this.evbusOpenPopupHandler);
        this.$evbus.$off(events.EV_APP_CLOSE_POPUP, this.evbusClosePopupHandler);
        this.$evbus.$off(events.EV_APP_CLOSE_ALL_WINDOWS, this.evbusCloseAllWindowsHandler);
    }
}
